import { breadcrumbs } from "@utils/breadcrumbs";
import { clsx } from "clsx";
import { Page, PageHeader } from "components/shared";
import { useEffect, useState } from "react";
import { useAdminDashboardContext } from "../../contexts/AdminDashboardProvider/AdminDashboardProvider";
import {
  AdminDashboardCalendar,
  AdminDashboardNavigationCalendars,
  AdminDashboardOverview,
  AdminDashboardViewModeTransitionContainer,
} from "./components";
import { AdminDashboardViewMode } from "./types";

export const AdminDashboard = () => {
  const { viewMode } = useAdminDashboardContext();

  const isCalendarView = viewMode === AdminDashboardViewMode.Calendar;
  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (viewMode === AdminDashboardViewMode.Calendar) setCalendarIsOpen(true);
  }, [viewMode]);

  return (
    <Page
      className={clsx(
        "relative gap-y-4 !pt-0",
        !isCalendarView && !calendarIsOpen && "!mt-[64px]",
        !isCalendarView && calendarIsOpen && "!mt-[338px]",
        isCalendarView && !calendarIsOpen && "!mt-[82px]",
        isCalendarView && calendarIsOpen && "!mt-[358px]"
      )}
    >
      <PageHeader breadcrumbs={[breadcrumbs.home(), breadcrumbs.dashboard()]} />

      <AdminDashboardNavigationCalendars
        calendarIsOpen={calendarIsOpen}
        setCalendarIsOpen={setCalendarIsOpen}
      />

      <AdminDashboardViewModeTransitionContainer
        className="bg-white pl-2 pr-1 rounded-lg z-[0]"
        show={isCalendarView}
      >
        <AdminDashboardCalendar
          className={clsx(
            calendarIsOpen ? "h-[calc(100vh-442px)]" : "h-[calc(100vh-168px)]"
          )}
        />
      </AdminDashboardViewModeTransitionContainer>

      <AdminDashboardViewModeTransitionContainer show={!isCalendarView}>
        <AdminDashboardOverview />
      </AdminDashboardViewModeTransitionContainer>
    </Page>
  );
};
