import { Popover, Transition } from "@headlessui/react";
import { getWeekNumber } from "@utils/dateTime";
import clsx from "clsx";
import { SIDEBAR_WIDTH } from "components/auth/AuthedLayout";
import {
  Icon,
  NavigationCalendars,
  OptionsToggleButton,
} from "components/shared";
import { useLayout } from "contexts/LayoutProvider";
import { useMemo } from "react";
import { AdminDashboardViewMode } from "sections/AdminDashboard/types";
import { useAdminDashboardContext } from "../../../../contexts/AdminDashboardProvider/AdminDashboardProvider";
import cellRenderer from "./cellRenderer";

type Props = {
  calendarIsOpen: boolean;
  setCalendarIsOpen: (open: boolean) => void;
};

export const AdminDashboardNavigationCalendars = ({
  calendarIsOpen,
  setCalendarIsOpen,
}: Props) => {
  const { screenWidth, isLessThanMdScreen } = useLayout();

  const {
    viewMode,
    selectedDate,
    selectedDateMidweek,
    setViewMode,
    setSelectedDate,
    getWeekDaysRangeString,
  } = useAdminDashboardContext();

  const cellRender = useMemo(() => cellRenderer(selectedDate), [selectedDate]);

  const isMinimizedScreen = screenWidth < 1000;

  const renderViewModeSelector = (
    <OptionsToggleButton
      radius={isMinimizedScreen ? 12 : 9}
      height={isMinimizedScreen ? "h-[48px]" : "h-9"}
      titleClassName="text-sm sm:text-base font-semibold"
      className={clsx(
        isMinimizedScreen ? "w-full mt-1" : "min-w-[10vw] max-w-[350px] -my-3"
      )}
      options={Object.values(AdminDashboardViewMode)}
      activeTab={Object.values(AdminDashboardViewMode).indexOf(viewMode)}
      setActiveTab={(index) =>
        setViewMode(Object.values(AdminDashboardViewMode)[index])
      }
    />
  );

  const renderNavigationCalendars = (
    <NavigationCalendars
      viewDate={selectedDate}
      className="justify-center my-1"
      miniClassName="w-fit items-center justify-end -mb-[9px]"
      calendarsWidth={isMinimizedScreen ? 0 : screenWidth - 300}
      cellRenderer={cellRender}
      setViewDate={setSelectedDate}
    />
  );

  return (
    <Popover
      className={clsx(
        "fixed top-[64px] h-fit -mx-4 px-4 isolate flex flex-col gap-y-3",
        "items-start py-4 backdrop-blur-sm w-full",
        "bg-white/[0.8] border border-slate-300/60 shadow z-[1000]"
      )}
      style={{
        width: `calc(100% - ${isLessThanMdScreen ? 0 : SIDEBAR_WIDTH}px)`,
      }}
    >
      <div className="flex flex-row items-center justify-between gap-2 sm:gap-4 w-full">
        <Popover.Button
          className={clsx(
            "flex flex-1 items-center gap-x-1 min-w-fit",
            "font-bold text-slate-800 shrink-0 group focus:outline-none",
            screenWidth < 550 ? "text-sm font-extrabold" : "text-2xl"
          )}
          onClick={() => setCalendarIsOpen(!calendarIsOpen)}
        >
          <span>
            Week {getWeekNumber(selectedDateMidweek)}:{" "}
            {getWeekDaysRangeString()}
          </span>

          {!isMinimizedScreen && (
            <Icon
              size={8}
              className="-my-1 pt-[2px]"
              color="text-slate-800 group-hover:text-blue-600"
              icon={calendarIsOpen ? "chevronUp" : "chevronDown"}
            />
          )}
        </Popover.Button>

        {isMinimizedScreen ? renderNavigationCalendars : renderViewModeSelector}
      </div>

      {!isMinimizedScreen && (
        <Transition
          as={Popover.Panel}
          show={calendarIsOpen}
          enter="transition ease-out duration-[50]"
          enterFrom="transform opacity-0 -translate-y-1"
          enterTo="transform opacity-100 translate-y-0"
          leave="transition ease-in duration-200"
          leaveFrom="transform opacity-100 translate-y-0"
          leaveTo="transform opacity-0 -translate-y-1"
          className="flex flex-row justify-center w-full"
        >
          {renderNavigationCalendars}
        </Transition>
      )}

      {isMinimizedScreen && renderViewModeSelector}
    </Popover>
  );
};
