import { gql } from "@apollo/client";
import {
  NameCell_DashboardStaffFragment,
  UserSortableField,
} from "@generated/graphql";
import { getUserPronounsText } from "@utils/labels";
import { Icon, Tooltip } from "components/shared";

import { CellProps, Column } from "react-table";

nameCell.fragments = {
  staff: gql`
    fragment NameCell_DashboardStaff on DashboardStaff {
      name
      userId
      cohortId
      engagementId
      pronouns
    }
  `,
};

export function nameCell<Fragment extends NameCell_DashboardStaffFragment>(
  setSelectedUserId: (userId: string) => void,
  setTeacherName: (teacherName: string) => void,
  setOpenAttendanceScorecard: (state: boolean) => void,
  setOpenInfractionsModal: (state: boolean) => void,
  setCohortAndEngagementIds: (
    cohortId: string | undefined,
    engagementId: string
  ) => void,
  width: number
): Column<Fragment> {
  return {
    id: UserSortableField.FullName,
    Header: "Name",
    accessor: "name",
    width,
    maxWidth: width,
    Cell: ({ row: { original } }: CellProps<Fragment>) => (
      <div className="flex items-center gap-2 group">
        <p className="text-black font-semibold">{original.name}</p>
        <p className="font-medium text-gray-950 text-[10px] leading-none pt-[3px]">
          {getUserPronounsText(original.pronouns, "", true)}
        </p>
        <Tooltip content="View Teacher Scorecard">
          <div
            className="h-6 w-6 flex flex-center cursor-pointer rounded-full bg-blue-700"
            onClick={() => {
              setSelectedUserId(original.userId);
              setOpenAttendanceScorecard(true);
            }}
          >
            <Icon icon="source" color="text-white" size={4} />
          </div>
        </Tooltip>
        <Tooltip content="Document Teacher Infraction">
          <div
            className="h-6 w-6 flex flex-center cursor-pointer rounded-full bg-red-500"
            onClick={() => {
              setSelectedUserId(original.userId);
              setTeacherName(original.name);
              setCohortAndEngagementIds(
                original.cohortId || undefined,
                original.engagementId
              );
              setOpenInfractionsModal(true);
            }}
          >
            <Icon icon="infraction" color="text-white" size={4} />
          </div>
        </Tooltip>
      </div>
    ),
  };
}
