import { gql, useQuery } from "@apollo/client";
import {
  EngagementSummaryTab_StartingThisWeekEngagementItemFragment,
  GetAdminDashboardOverviewDataQuery,
  GetAdminDashboardOverviewDataQueryVariables,
  TeachersSummary_MentorWeeksDetailsFragment,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { LoadingPage } from "components/shared/Loading";
import isThisWeek from "date-fns/isThisWeek";
import { useAdminDashboardContext } from "../../../../contexts/AdminDashboardProvider/AdminDashboardProvider";
import {
  EngagementsSummaryTabs,
  PaymentsSummary,
  SpecialEventsSummary,
  StaffingSummary,
  TeachersSummary,
  WeekSummary,
} from "./components";
import { EngagementSummaryTab } from "./components/engagementsSummary/components/tabs/EngagementSummaryTab";
import { RedashDashboards } from "./components/RedashDashboard";
import { useInfiniteScroll } from "./useInfiniteScroll";

const GET_ADMIN_DASHBOARD_OVERVIEW_DATA = gql`
  query GetAdminDashboardOverviewData($input: AdminDashboardBaseInput!) {
    getAdminDashboardData(input: $input) {
      adminWeeksDetails {
        ...WeekSummary_AdminDashboard
      }
      startingThisWeekEngagements {
        ...EngagementSummaryTab_StartingThisWeekEngagementItem
      }
      teacherWeeksDetails {
        ...TeacherSummary_TeacherWeekDetails
      }
      mentorsWeeksDetails {
        ...TeachersSummary_MentorWeeksDetails
      }
      paymentsWeeksDetails {
        ...PaymentsSummary_PaymentsDetails
      }
      staffingDetails {
        ...StaffingSummary_StaffingDetails
      }
      specialEventDetails {
        ...SpecialEventsSummary_SpecialEventDetails
      }
      managedEngagements {
        ...RedashDashboards_ManagedEngagementItem
      }
    }
  }
  ${EngagementSummaryTab.fragments.startingThisWeekEngagement}
  ${WeekSummary.fragments.weekSummary}
  ${PaymentsSummary.fragments.paymentsSummary}
  ${TeachersSummary.fragments.teachersSummary}
  ${StaffingSummary.fragments.staffingSummary}
  ${SpecialEventsSummary.fragments.specialEventsSummary}
  ${TeachersSummary.fragments.mentorsWeeksDetails}
  ${RedashDashboards.fragments.managedEngagementItems}
`;

export function AdminDashboardOverview() {
  const { selectedDateMidweek } = useAdminDashboardContext();
  const { data, loading, fetchMore } = useQuery<
    GetAdminDashboardOverviewDataQuery,
    GetAdminDashboardOverviewDataQueryVariables
  >(GET_ADMIN_DASHBOARD_OVERVIEW_DATA, {
    variables: { input: { selectedDate: selectedDateMidweek.getTime() } },
    onError: (error) => fetchErrToast("overview data.", error),
  });

  const viewingPast =
    selectedDateMidweek.getTime() < Date.now() &&
    !isThisWeek(selectedDateMidweek);

  const { fetchMoreData: fetchMoreEngagementsData } = useInfiniteScroll<
    GetAdminDashboardOverviewDataQuery,
    GetAdminDashboardOverviewDataQueryVariables,
    EngagementSummaryTab_StartingThisWeekEngagementItemFragment
  >({
    loading,
    fetchMore,
    idResolver: ({ id }) => id,
    itemResolver: (data) =>
      data.getAdminDashboardData.startingThisWeekEngagements,
    inputResolver: (startingThisWeekEngagementsCursor) => ({
      input: {
        selectedDate: selectedDateMidweek.getTime(),
        startingThisWeekEngagementsCursor,
      },
    }),
    dataCombiner: (prev, startingThisWeekEngagements) => ({
      ...prev,
      startingThisWeekEngagements,
    }),
  });

  const { fetchMoreData: fetchMoreMentorsData } = useInfiniteScroll<
    GetAdminDashboardOverviewDataQuery,
    GetAdminDashboardOverviewDataQueryVariables,
    TeachersSummary_MentorWeeksDetailsFragment
  >({
    loading,
    fetchMore,
    idResolver: ({ id }) => id,
    itemResolver: (data) => data.getAdminDashboardData.mentorsWeeksDetails,
    inputResolver: (mentorsWeeksDetailsCursor) => ({
      input: {
        selectedDate: selectedDateMidweek.getTime(),
        mentorsWeeksDetailsCursor,
      },
    }),
    dataCombiner: (prev, mentorsWeeksDetails) => ({
      ...prev,
      mentorsWeeksDetails,
    }),
  });

  const { getAdminDashboardData: dashData } = data ?? {};
  const engagements = dashData?.startingThisWeekEngagements;

  return (
    <div className="flex flex-col gap-y-5 mb-48 !mt-4">
      {loading && <LoadingPage />}
      <WeekSummary
        data={dashData?.adminWeeksDetails}
        viewingPast={viewingPast}
      />

      <EngagementsSummaryTabs
        data={engagements}
        loading={loading}
        fetchMoreData={fetchMoreEngagementsData}
      />
      <TeachersSummary
        teacherWeeksDetails={dashData?.teacherWeeksDetails}
        mentorsWeeksDetails={dashData?.mentorsWeeksDetails}
        loading={loading}
        fetchMoreData={fetchMoreMentorsData}
        viewingPast={viewingPast}
      />
      <PaymentsSummary data={dashData?.paymentsWeeksDetails} />
      <StaffingSummary data={dashData?.staffingDetails} />
      <SpecialEventsSummary data={dashData?.specialEventDetails} />
      <RedashDashboards data={dashData?.managedEngagements} />
    </div>
  );
}
