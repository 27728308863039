import { gql } from "@apollo/client";
import { SessionCell_DashboardStaffFragment } from "@generated/graphql";
import { tableHeaderStyle } from "@utils/styleStrings";
import { SessionReportDetails } from "components/shared/SessionReports/components/SessionReportDetails";
import { SessionReportModal } from "components/shared/SessionReports/SessionReportModal/SessionReportModal";
import { useState } from "react";
import { CellProps, Column } from "react-table";

sessionCell.fragments = {
  staff: gql`
    fragment SessionCell_DashboardStaff on DashboardStaff {
      cohortSessionId
      sessionReportId
      cohortSessionEndDateTime
      hasSessionReportConcerns
    }
  `,
};

export function sessionCell<
  Fragment extends SessionCell_DashboardStaffFragment,
>(): Column<Fragment> {
  return {
    Header: () => <span className={tableHeaderStyle()}>Session Report</span>,
    width: 160,
    maxWidth: 160,
    accessor: "sessionReportId",
    Cell: ({
      row: {
        original: {
          sessionReportId,
          cohortSessionId,
          cohortSessionEndDateTime,
          hasSessionReportConcerns,
        },
      },
    }: CellProps<Fragment>) => {
      const [show, setShow] = useState(false);

      return (
        <>
          <SessionReportDetails
            canCreateReport={false}
            hasSessionReport={!!sessionReportId}
            hasConcerns={hasSessionReportConcerns}
            sessionEndDateTime={new Date(cohortSessionEndDateTime).getTime()}
            onViewSessionReport={() =>
              cohortSessionId && sessionReportId && setShow(true)
            }
          />

          {cohortSessionId && sessionReportId && (
            <SessionReportModal
              readOnly
              show={show}
              sessionReportId={sessionReportId}
              cohortSessionId={cohortSessionId}
              onClose={() => setShow(false)}
            />
          )}
        </>
      );
    },
  };
}
