import { gql } from "@apollo/client";
import {
  AttendanceCell_DashboardStaffFragment,
  DashboardAssignmentRole,
  DashboardAttendanceStatus,
} from "@generated/graphql";
import { tableHeaderStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { AttendanceRobot, Icon, Tooltip } from "components/shared";
import { CellProps, Column } from "react-table";

attendanceCell.fragments = {
  staff: gql`
    fragment AttendanceCell_DashboardStaff on DashboardStaff {
      name
      role
      attendance {
        id
        status
        automaticallyUpdated
      }
    }
  `,
};

export function attendanceCell<
  Fragment extends AttendanceCell_DashboardStaffFragment,
>(
  openAttendanceModal: (value: boolean) => void,
  setAttendanceId: (id: string) => void,
  setTeacherName: (teacherName: string) => void
): Column<Fragment> {
  return {
    Header: () => <span className={tableHeaderStyle()}>Attendance Status</span>,
    width: 200,
    maxWidth: 200,
    accessor: "attendance",
    Cell: ({ row: { original } }: CellProps<Fragment>) => (
      <div className="flex flex-row flex-center gap-x-4">
        <Tooltip
          content={AttendanceStatusColors[original.attendance.status].content}
          tooltipProps={{ place: "left" }}
          className="flex items-center"
        >
          <div
            className={clsx(
              "w-3 h-3 rounded-full cursor-pointer",
              AttendanceStatusColors[original.attendance.status].color
            )}
          />
        </Tooltip>
        <AttendanceRobot
          isAutomaticallyUpdated={!!original.attendance.automaticallyUpdated}
        />
        {original.attendance.id &&
          original.role === DashboardAssignmentRole.Tt && (
            <Tooltip
              content="Take attendance"
              tooltipProps={{ place: "right" }}
              className="flex items-center cursor-pointer"
            >
              <Icon
                icon="editUser"
                color="text-slate-500"
                onClick={() => {
                  if (!original.attendance.id) return;
                  setAttendanceId(original.attendance.id);
                  setTeacherName(original.name);
                  openAttendanceModal(true);
                }}
              />
            </Tooltip>
          )}
      </div>
    ),
  };
}

const AttendanceStatusColors = {
  [DashboardAttendanceStatus.Unknown]: {
    color: "bg-gray-400",
    content: "The attendance was not marked",
  },
  [DashboardAttendanceStatus.Present]: {
    color: "bg-emerald-500",
    content: "The attendance was marked as PRESENT",
  },
  [DashboardAttendanceStatus.Absent]: {
    color: "bg-rose-500",
    content: "The attendance was marked as ABSENT",
  },
};
