import { gql } from "@apollo/client";

import { EngagementsSummary_StartingThisWeekEngagementItemFragment } from "@generated/graphql";
import { OptionsToggleButton } from "components/shared/Buttons/OptionsToggleButton";
import { DashboardCard } from "components/shared/DataVisualization/DashboardCard";
import { DashboardSection } from "components/shared/DataVisualization/DashboardSection";
import { useAdminDashboardContext } from "contexts/AdminDashboardProvider/AdminDashboardProvider";
import { useLayout } from "contexts/LayoutProvider";
import { useState } from "react";
import { EngagementTypeTab } from "sections/AdminDashboard/types";
import { useDebounce } from "use-debounce";
import { getEngagementTabTitles } from "../helpers";
import { EngagementStaffingSummaryTab } from "./components/tabs/EngagementStaffingSummaryTab";
import { EngagementSummaryTab } from "./components/tabs/EngagementSummaryTab";

EngagementsSummaryTabs.fragments = {
  startingThisWeekEngagements: gql`
    fragment EngagementsSummary_StartingThisWeekEngagementItem on StartingThisWeekEngagementItem {
      id
      organizationId
      organizationName
      engagementId
      engagementName
      launchDate
      endDate
      cohortsCount
      studentsCount
      staffCount {
        totalCount
        mentorsCount
        tutorsCount
        instructionalSupportsCount
      }
    }
  `,
};

type Props = {
  data?: EngagementsSummary_StartingThisWeekEngagementItemFragment[];
  fetchMoreData?: () => void;
  loading?: boolean;
};

export function EngagementsSummaryTabs({
  data,
  fetchMoreData,
  loading,
}: Props) {
  const { getWeekRangeString, selectedDateIsThisWeek } =
    useAdminDashboardContext();
  const [engagementTab, setEngagementTab] = useState<number>(0);
  const [debouncedActiveTab] = useDebounce(engagementTab, 250);
  const { isLessThanMdScreen, isLessThanXlScreen } = useLayout();

  const options = getEngagementTabTitles(
    selectedDateIsThisWeek ? undefined : `(${getWeekRangeString()})`,
    isLessThanMdScreen,
    isLessThanXlScreen
  );

  const getIndex = (value: EngagementTypeTab): number =>
    Object.values(EngagementTypeTab).indexOf(value);

  return (
    <DashboardSection title="Engagements Summary">
      <OptionsToggleButton
        options={options}
        height="h-[48px]"
        activeTab={engagementTab}
        activeTabColor="bg-slate-700"
        titleClassName="text-lg font-semibold"
        setActiveTab={setEngagementTab}
      />
      <DashboardCard className="!p-4">
        {debouncedActiveTab ===
          getIndex(EngagementTypeTab.startingThisWeek) && (
          <EngagementSummaryTab
            items={data}
            loading={loading}
            dataName={"Engagements starting this week"}
            emptyStateSubtitle={"No engagements starting this week."}
            fetchMoreData={fetchMoreData}
          />
        )}
        {debouncedActiveTab === getIndex(EngagementTypeTab.staffing) && (
          <EngagementStaffingSummaryTab
            dataName={"Engagements in staffing"}
            emptyStateSubtitle={"No Engagements in staffing."}
          />
        )}
      </DashboardCard>
    </DashboardSection>
  );
}
