import { DateTimeRange, getWeekRangeInLocalTimeZone } from "@utils/dateTime";
import addDays from "date-fns/addDays";
import isThisWeek from "date-fns/isThisWeek";
import setDay from "date-fns/setDay";
import { getShortReadableDateRangeString } from "helpers/dateText";
import noop from "lodash/noop";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AdminDashboardViewMode } from "../../sections/AdminDashboard/types";

type AdminDashboardContextProps = {
  viewMode: AdminDashboardViewMode;
  setViewMode: (mode: AdminDashboardViewMode) => void;
  selectedDate: Date;
  selectedDateMidweek: Date;
  setSelectedDate: (date: Date) => void;
  selectedDateIsThisWeek: boolean;
  weekRangeLocal: DateTimeRange;
  getWeekRangeString: () => string;
  getWeekDaysRangeString: () => string;
};

const initialState: AdminDashboardContextProps = {
  viewMode: AdminDashboardViewMode.Overview,
  setViewMode: noop,
  selectedDate: new Date(),
  selectedDateMidweek: setDay(new Date(), 3),
  setSelectedDate: noop,
  selectedDateIsThisWeek: true,
  weekRangeLocal: getWeekRangeInLocalTimeZone(new Date()),
  getWeekRangeString: () => "",
  getWeekDaysRangeString: () => "",
};

export const AdminDashboardContext =
  createContext<AdminDashboardContextProps>(initialState);

type Props = { children: ReactNode };

export const AdminDashboardProvider = ({ children }: Props) => {
  const [viewMode, setViewMode] = useState(initialState.viewMode);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateMidweek, setSelectedDateMidweek] = useState(
    initialState.selectedDateMidweek
  );

  const handleSetSelectedDate = (date: Date) => {
    setSelectedDate(date);
    setSelectedDateMidweek(setDay(date, 3));
  };

  const [selectedDateIsThisWeek, setSelectedDateIsThisWeek] = useState(
    initialState.selectedDateIsThisWeek
  );
  const [weekRangeLocal, setWeekRangeLocal] = useState(
    initialState.weekRangeLocal
  );
  const getWeekRangeString = useCallback(
    () =>
      getShortReadableDateRangeString(weekRangeLocal.start, weekRangeLocal.end),
    [weekRangeLocal]
  );

  const getWeekDaysRangeString = useCallback(() => {
    return getShortReadableDateRangeString(
      addDays(weekRangeLocal.start, 1),
      addDays(weekRangeLocal.end, -1)
    );
  }, [weekRangeLocal]);

  useEffect(() => {
    setSelectedDateIsThisWeek(isThisWeek(selectedDate));
    setWeekRangeLocal(getWeekRangeInLocalTimeZone(selectedDate));
  }, [selectedDate]);

  const contextValue = useMemo(
    () => ({
      viewMode,
      setViewMode,
      selectedDate,
      selectedDateMidweek,
      setSelectedDate: handleSetSelectedDate,
      selectedDateIsThisWeek,
      weekRangeLocal,
      getWeekRangeString,
      getWeekDaysRangeString,
    }),
    [
      getWeekDaysRangeString,
      getWeekRangeString,
      selectedDate,
      selectedDateIsThisWeek,
      selectedDateMidweek,
      viewMode,
      weekRangeLocal,
    ]
  );

  return (
    <AdminDashboardContext.Provider value={contextValue}>
      {children}
    </AdminDashboardContext.Provider>
  );
};

export const useAdminDashboardContext = (): AdminDashboardContextProps =>
  useContext(AdminDashboardContext);
