import { gql } from "@apollo/client";
import {
  EngagementStatus,
  MentorsSummaryTab_EngagementDetailsFragment,
  MentorsSummaryTab_MentorWeeksItemFragment,
} from "@generated/graphql";
import { Routes } from "@utils/routes";
import { ArchivedBadge, Icon, Link, Table } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { CellProps } from "react-table";
import { AdminMode } from "types/global";

const MENTORS_SUMMARY_ENGAGEMENT_DETAILS = gql`
  fragment MentorsSummaryTab_EngagementDetails on EngagementDetails {
    id
    engagementId
    organizationId
    engagementName
    engagementStatus
    shiftsDetails
  }
`;

MentorsSummaryTab.fragments = {
  mentorsWeeksDetails: gql`
    fragment MentorsSummaryTab_MentorWeeksItem on MentorWeeksItem {
      id
      userId
      userName
      weeksTutoringHoursCount
      weeksTutoringHoursCompleted
      engagementDetails {
        ...MentorsSummaryTab_EngagementDetails
      }
    }
    ${MENTORS_SUMMARY_ENGAGEMENT_DETAILS}
  `,
};

type Props = {
  items?: MentorsSummaryTab_MentorWeeksItemFragment[];
  loading?: boolean;
  dataName: string;
  emptyStateSubtitle: string;
  fetchMoreData?: () => void;
};

export function MentorsSummaryTab({
  items,
  loading,
  fetchMoreData,
  emptyStateSubtitle,
  dataName,
}: Props) {
  const columns = useColumns();

  return (
    <Table
      columns={columns}
      data={items ?? []}
      className="max-h-[500px] overflow-y-auto text-start"
      verticalDividers
      pageSize={100}
      emptyStateIcon={<Icon icon="teacher" size={8} />}
      loading={loading}
      freezeHeaders
      fetchMoreData={fetchMoreData}
      emptyStateSubtitle={emptyStateSubtitle}
      dataName={dataName}
      tableComponentId="mentors-summary-tab"
    />
  );
}

const useColumns = () => {
  const { setAdminMode } = useAuth();

  return [
    {
      accessor: (row: MentorsSummaryTab_MentorWeeksItemFragment) =>
        row.userName,
      Header: "Name",
      sortType: "string",
      Cell: ({
        row: {
          original: { userId, userName },
        },
      }: CellProps<MentorsSummaryTab_MentorWeeksItemFragment>) => (
        <Link
          route={Routes.user.details}
          routeProps={[userId]}
          onClick={() => {
            setAdminMode(AdminMode.Managing, false, Routes.user.details, [
              userId,
            ]);
          }}
        >
          {userName}
        </Link>
      ),
    },
    {
      Header: "Engagements & Shifts",
      accessor: (
        row: MentorsSummaryTab_MentorWeeksItemFragment
      ): MentorsSummaryTab_EngagementDetailsFragment[] => row.engagementDetails,
      Cell: ({
        value,
      }: CellProps<
        MentorsSummaryTab_MentorWeeksItemFragment,
        MentorsSummaryTab_EngagementDetailsFragment[]
      >) => (
        <Table<MentorsSummaryTab_EngagementDetailsFragment>
          data={value}
          showPagination={false}
          verticalDividers
          className="min-h-0 text-start"
          showHeaders={false}
          columns={[
            {
              accessor: "engagementName",
              sortType: "string",
              width: 380,
              Cell: ({
                row,
              }: CellProps<MentorsSummaryTab_EngagementDetailsFragment>) => (
                <Link
                  className="flex flex-row flex-wrap gap-y-1"
                  route={Routes.engagement.details}
                  routeProps={[row.original.engagementId]}
                  onClick={() => {
                    setAdminMode(
                      AdminMode.Managing,
                      false,
                      Routes.engagement.details,
                      [row.original.engagementId]
                    );
                  }}
                >
                  {row.original.engagementName}
                  {row.original.engagementStatus ===
                    EngagementStatus.Archived && (
                    <ArchivedBadge className="ml-auto" />
                  )}
                </Link>
              ),
            },
            {
              accessor: "shiftsDetails",

              Cell: ({
                row,
              }: CellProps<MentorsSummaryTab_EngagementDetailsFragment>) => (
                <div className="flex flex-col gap-x-1">
                  {row.original.shiftsDetails.map((shift) => (
                    <p key={shift}>{shift}</p>
                  ))}
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      accessor: (row: MentorsSummaryTab_MentorWeeksItemFragment) =>
        row.weeksTutoringHoursCount,
      Header: "Hours/Week",
      sortType: "number",
      Cell: ({
        row: {
          original: { weeksTutoringHoursCount },
        },
      }: CellProps<MentorsSummaryTab_MentorWeeksItemFragment>) =>
        weeksTutoringHoursCount,
    },
    {
      accessor: (row: MentorsSummaryTab_MentorWeeksItemFragment) =>
        row.weeksTutoringHoursCompleted,
      Header: "Progress",
      sortType: "number",
      Cell: ({
        row: {
          original: { weeksTutoringHoursCompleted, weeksTutoringHoursCount },
        },
      }: CellProps<MentorsSummaryTab_MentorWeeksItemFragment>) =>
        `${(weeksTutoringHoursCount === 0
          ? 0
          : (weeksTutoringHoursCompleted / weeksTutoringHoursCount) * 100
        ).toFixed(0)}%`,
    },
  ];
};
