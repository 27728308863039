export enum SupportedIanaTimeZone {
  PacificPagoPago = "Pacific/Pago_Pago",
  PacificHonolulu = "Pacific/Honolulu",
  AmericaAnchorage = "America/Anchorage",
  AmericaLosAngeles = "America/Los_Angeles",
  AmericaPhoenix = "America/Phoenix",
  AmericaDenver = "America/Denver",
  AmericaChicago = "America/Chicago",
  AmericaNewYork = "America/New_York",
  AmericaPuertoRico = "America/Puerto_Rico",
  PacificGuam = "Pacific/Guam",
}
