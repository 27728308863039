import { gql } from "@apollo/client";
import {
  DashboardAssignmentRole,
  RoleCell_DashboardStaffFragment,
  TutoringPreferencesPosition,
} from "@generated/graphql";
import { tableCellStyle, tableHeaderStyle } from "@utils/styleStrings";
import { AccountRoleBadge } from "components/shared";

import { CellProps, Column } from "react-table";

roleCell.fragments = {
  staff: gql`
    fragment RoleCell_DashboardStaff on DashboardStaff {
      role
    }
  `,
};

export function roleCell<
  Fragment extends RoleCell_DashboardStaffFragment,
>(): Column<Fragment> {
  return {
    Header: () => <span className={tableHeaderStyle()}>Role</span>,
    width: 70,
    maxWidth: 70,
    accessor: "role",
    Cell: ({ row: { original } }: CellProps<Fragment>) => (
      <div className={tableCellStyle()}>
        <AccountRoleBadge
          mode="tiny"
          accountRole={
            original.role === DashboardAssignmentRole.Tt
              ? TutoringPreferencesPosition.TeacherTutor
              : TutoringPreferencesPosition.InstructionalSupport
          }
        />
      </div>
    ),
  };
}
