import { MentorDashboardQuery } from "@generated/graphql";
import { Routes } from "@utils/routes";
import { makeCacheKey } from "@utils/strings";
import { IconText, Link, Table } from "components/shared";
import { useLayout } from "contexts/LayoutProvider";
import { useMemo } from "react";
import { Column } from "react-table";
import {
  MAX_NAME_COL_WIDTH,
  MIN_NAME_COL_WIDTH,
  NAME_PERCENT_WIDTH,
  OTHER_WIDTH,
} from "../constants";
import {
  attendanceCell,
  detailsCell,
  hostPresenceCell,
  nameCell,
  roleCell,
  sessionCell,
} from "./cells";

type Props = {
  startTime: Date;
  duration: number;
  engagement: MentorDashboardQuery["getMentorDashboard"][0]["engagements"][0];
  setAttendanceId: (id: string) => void;
  setSelectedUserId: (userId: string) => void;
  setTeacherName: (teacherName: string) => void;
  setOpenAttendanceModal: (open: boolean) => void;
  setOpenAttendanceScorecard: (open: boolean) => void;
  setCohortAndEngagementIds: (
    cohortId: string | undefined,
    engagementId: string
  ) => void;
  setOpenInfractionsModal: (open: boolean) => void;
};

export const MentorDashboardEngagement = ({
  duration,
  startTime,
  engagement,
  setTeacherName,
  setAttendanceId,
  setSelectedUserId,
  setOpenAttendanceModal,
  setOpenInfractionsModal,
  setCohortAndEngagementIds,
  setOpenAttendanceScorecard,
}: Props) => {
  const { screenWidth } = useLayout();

  const nameWidth = useMemo(() => {
    const unassignedWidth = screenWidth - OTHER_WIDTH;
    const calculatedNameColWidth = unassignedWidth * NAME_PERCENT_WIDTH;
    const minNameWidth = Math.min(calculatedNameColWidth, MAX_NAME_COL_WIDTH);
    return Math.max(minNameWidth, MIN_NAME_COL_WIDTH);
  }, [screenWidth]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex text-lg">
        <IconText
          icon="engagement"
          child={
            <Link
              route={Routes.engagementsDashboard}
              routeProps={[`engagementId=${engagement.engagementId}`]}
            >
              {engagement.name}
            </Link>
          }
        />
      </div>

      <Table
        showPagination={false}
        columns={getColumns(
          engagement,
          makeCacheKey(startTime.getTime(), duration),
          setSelectedUserId,
          setOpenAttendanceScorecard,
          setOpenInfractionsModal,
          setCohortAndEngagementIds,
          setOpenAttendanceModal,
          setAttendanceId,
          setTeacherName,
          nameWidth
        )}
        className="min-h-0"
        data={engagement.staff}
      />
    </div>
  );
};

type StaffTrackerRow =
  MentorDashboardQuery["getMentorDashboard"][0]["engagements"][0]["staff"][0];

const getColumns = (
  engagement: MentorDashboardQuery["getMentorDashboard"][0]["engagements"][0],
  timeslot: string,
  setSelectedUserId: (userId: string) => void,
  setOpenAttendanceScorecard: (open: boolean) => void,
  setOpenInfractionsModal: (open: boolean) => void,
  setCohortAndEngagementIds: (
    cohortId: string | undefined,
    engagementId: string
  ) => void,
  setOpenAttendanceModal: (open: boolean) => void,
  setAttendanceId: (id: string) => void,
  setTeacherName: (teacherName: string) => void,
  nameWidth: number = 200
) => {
  const columns: Column<StaffTrackerRow>[] = [
    nameCell(
      setSelectedUserId,
      setTeacherName,
      setOpenAttendanceScorecard,
      setOpenInfractionsModal,
      setCohortAndEngagementIds,
      nameWidth
    ),
    roleCell(),
    hostPresenceCell(engagement, timeslot),
    attendanceCell(setOpenAttendanceModal, setAttendanceId, setTeacherName),
    sessionCell(),
    detailsCell(engagement, timeslot),
  ];
  return columns;
};
