import { gql } from "@apollo/client";
import { EngagementSummaryItem } from "@generated/graphql";
import { normalizeDateFromUTCDateTime } from "@utils/dateTime";
import { Routes } from "@utils/routes";
import { Icon, Link, Table } from "components/shared";
import {
  getSubHeaderPillItem,
  renderIndicatorCell,
} from "components/shared/Table/helpers";
import { useAuth } from "contexts/AuthProvider";
import { getShortReadableDate } from "helpers";
import { useMemo } from "react";
import { CellProps, Column } from "react-table";
import { StaffType, staffTypeColors } from "sections/AdminDashboard/types";
import { AdminMode } from "types/global";

EngagementSummaryTab.fragments = {
  startingThisWeekEngagement: gql`
    fragment EngagementSummaryTab_StartingThisWeekEngagementItem on StartingThisWeekEngagementItem {
      id
      endDate
      launchDate
      engagementId
      cohortsCount
      studentsCount
      organizationId
      engagementName
      organizationName
      staffCount {
        totalCount
        tutorsCount
        mentorsCount
        instructionalSupportsCount
      }
    }
  `,
};

type Props<T> = {
  items?: T[];
  dataName: string;
  loading?: boolean;
  emptyStateSubtitle: string;
  fetchMoreData?: () => void;
};

export function EngagementSummaryTab<T extends EngagementSummaryItem>({
  items,
  loading,
  dataName,
  emptyStateSubtitle,
  fetchMoreData,
}: Props<T>) {
  const columns = useColumns<T>();

  return (
    <Table
      freezeHeaders
      pageSize={100}
      verticalDividers
      loading={loading}
      columns={columns}
      data={items ?? []}
      dataName={dataName}
      emptyStateSubtitle={emptyStateSubtitle}
      emptyStateIcon={<Icon icon="engagement" size={8} />}
      className="max-h-[500px] overflow-y-auto text-start"
      tableComponentId="engagements-starting-this-week-summary-tab"
      fetchMoreData={fetchMoreData}
    />
  );
}

function useColumns<T extends EngagementSummaryItem>(): Column<T>[] {
  const { setAdminMode, setActiveRoute } = useAuth();

  return useMemo(() => {
    const { MT, TT, IS, Total } = StaffType;

    const staffTypeMapDetails = [
      { name: "Total", color: staffTypeColors[Total] },
      { name: "Mentor Teachers", color: staffTypeColors[MT] },
      { name: "Instructional Support", color: staffTypeColors[IS] },
      { name: "Teacher-Tutors", color: staffTypeColors[TT] },
    ];

    return [
      {
        accessor: "organizationName" as keyof T,
        Header: "Organization",
        sortType: "string",
        Cell: ({
          row: {
            original: { organizationId, organizationName },
          },
        }: CellProps<T>) => (
          <Link
            route={Routes.org.details}
            routeProps={[organizationId]}
            onClick={() => {
              setAdminMode(AdminMode.Managing);
              setActiveRoute(Routes.org.details, [organizationId]);
            }}
          >
            {organizationName}
          </Link>
        ),
      },
      {
        accessor: "engagementName" as keyof T,
        Header: "Engagement",
        sortType: "string",
        Cell: ({
          row: {
            original: { engagementId, engagementName },
          },
        }: CellProps<T>) => (
          <Link
            route={Routes.engagement.details}
            routeProps={[engagementId]}
            onClick={() => {
              setAdminMode(AdminMode.Managing);
              setActiveRoute(Routes.engagement.details, [engagementId]);
            }}
          >
            {engagementName}
          </Link>
        ),
      },
      {
        accessor: (row: T) => new Date(row.launchDate),
        Header: "Launch Date",
        sortType: "datetime",
        Cell: ({
          row: {
            original: { launchDate },
          },
        }: CellProps<T>) =>
          getShortReadableDate(
            normalizeDateFromUTCDateTime(new Date(launchDate ?? 0))
          ),
      },
      {
        accessor: (row: T) => new Date(row.endDate),
        Header: "End Date",
        sortType: "datetime",
        Cell: ({
          row: {
            original: { endDate },
          },
        }: CellProps<T>) =>
          getShortReadableDate(
            normalizeDateFromUTCDateTime(new Date(endDate ?? 0))
          ),
      },
      {
        accessor: "cohortsCount" as keyof T,
        Header: "Cohorts",
        sortType: "number",
        Cell: ({
          row: {
            original: { cohortsCount },
          },
        }: CellProps<T>) => cohortsCount,
      },
      {
        accessor: "studentsCount" as keyof T,
        Header: "Students",
        sortType: "number",
        Cell: ({
          row: {
            original: { studentsCount },
          },
        }: CellProps<T>) => studentsCount,
      },
      {
        Header: () => (
          <div className="flex gap-2">
            {staffTypeMapDetails.map(({ name, color }) =>
              getSubHeaderPillItem(name, color)
            )}
          </div>
        ),
        id: "staffCount",
        sortType: "number",
        accessor: (row: T) => row.staffCount.totalCount,
        Cell: ({
          row: {
            original: { staffCount },
          },
        }: CellProps<T>) => {
          const values = [
            staffCount.totalCount,
            staffCount.mentorsCount,
            staffCount.instructionalSupportsCount,
            staffCount.tutorsCount,
          ];

          return (
            <div className="flex gap-2">
              {staffTypeMapDetails
                .map((status, index) => ({
                  ...status,
                  value: values[index],
                }))
                .map(({ name, color, value }) =>
                  renderIndicatorCell(name, color, value)
                )}
            </div>
          );
        },
      },
    ];
  }, [setAdminMode, setActiveRoute]);
}
